import React from 'react'
import Counter from '../components/counter'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Image from '../components/image'

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title='404: Not found' />
      <div className='fixed inset-0'>
        <Image className='w-full h-full object-cover' />
      </div>
      <div className='z-50 h-screen flex flex-col justify-center items-center m-auto text-center relative'>
        <h1 className='m-0 text-secondary text-8xl md:text-giant'>
          <Counter value={404} />
        </h1>
        <p className='m-0 text-white font-bold text-xl w-8/12 md:w-2/5'>
          Questo numero non è una statistica ma sei solamente finito in una
          pagina che non esiste. Premi il bottone per riprendere la navigazione.
        </p>
        <div className='mt-16'>
          <Link
            className='block uppercase xl:hover:bg-mandarin xl:hover:text-white sm:text-xl border-solid border-3 no-underline border-mandarin text-mandarin font-bold px-8 py-4 rounded-lg'
            to='/'
          >
            Torna alla home
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
